* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #3977c8;
}

a {
  text-decoration: none;
}
