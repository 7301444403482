* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

:root {
  --primary: #3977c8;
}

a {
  text-decoration: none;
}

@property --size {
  syntax: "<number>";
  inherits: false;
  initial-value: 1;
}

.small {
  --size: 0.75;
}

.large {
  --size: 1.5;
}

.huge {
  --size: 1;
}

/** Variables */

body {
  background-image: url("main-bg.21c5e458.svg");
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #001220;
  color: #fff;
}

:root {
  color-scheme: dark;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page > main {
  flex-grow: 1;
}

nav {
  /* background: #444;
   color: #fff;
  */
}

nav a {
  color: #ddd;
}

nav .container {
  display: grid;
  grid-template-columns: 6rem 1fr;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

nav .actions {
  grid-row: span 2;
}

nav .actions a {
  margin-right: 1rem;
}

@media screen and (min-width: 768px) {
  nav .container {
    grid-template-columns: 6rem 1fr auto;
  }
}

footer {
  /* background-color: #f8f8f8; */
  padding: 0.5rem;
  text-align: right;
  font-size: 0.7rem;
}

.container {
  max-width: 70rem;
  width: 90%;
  margin: auto;
}

section {
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 7, 14, 0.3);
}

.overlay {
  position: fixed;
  overflow: auto;
  inset: 0;
}

input {
  font-size: calc(var(--size) * 1rem);
}

input.inline {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0.5rem;
  color: #fff;
  width: 100%;
  max-width: 30rem;
}

input.inline:focus {
  outline: none;
  border-bottom: 1px solid var(--primary);
}

.toaster {
  pointer-events: none;
}

.toaster .toast {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.5em 0.5em;
  border-radius: 0.5rem;
  position: fixed;
  z-index: 999;
  left: 50%;
  font-size: 0.75rem;
  animation: toast 5s linear forwards;
}

@keyframes toast {
  from {
    opacity: 0;
    transform: translate(-50%, 50px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50px);
  }
}

.tag {
  background-color: #444;
  color: #fff;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.75rem;
}

.game {
  background-color: #222;
  color: #fff;
}

.game h4 {
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
}

/* Overrides */

/* Custom elements */
/*# sourceMappingURL=template.e64f3c2a.css.map */
