.game {
  background-color: #222;
  color: #fff;

  h4 {
    margin-top: 0;
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
  }
}
