.toaster {
  pointer-events: none;

  .toast {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.5em 0.5em;
    border-radius: 0.5rem;

    position: fixed;
    z-index: 999;
    left: 50%;

    font-size: 0.75rem;

    animation: toast 5s linear forwards;
  }
}

@keyframes toast {
  from {
    opacity: 0;
    transform: translate(-50%, 50px);
  }

  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50px);
  }
}
