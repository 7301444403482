input {
  font-size: calc(var(--size) * 1rem);

  &.inline {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    color: #fff;
    width: 100%;
    max-width: 30rem;

    &:focus {
      outline: none;
      border-bottom: 1px solid var(--primary);
    }
  }
}
