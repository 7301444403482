body {
  background-image: url('../assets/image/main-bg.svg');
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #001220;
  color: #fff;
}

:root {
  color-scheme: dark;
}
