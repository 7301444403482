@property --size {
  syntax: '<number>';
  inherits: false;
  initial-value: 1;
}

.small {
  --size: 0.75;
}

.large {
  --size: 1.5;
}

.huge {
  --size: 1;
}
