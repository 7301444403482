nav {
  /* background: #444;
  color: #fff;
 */
  a {
    color: #ddd;
  }

  .container {
    display: grid;
    grid-template-columns: 6rem 1fr;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .actions {
    grid-row: span 2;

    a {
      margin-right: 1rem;
    }
  }
}

@media screen and (min-width: 768px) {
  nav .container {
    grid-template-columns: 6rem 1fr auto;
  }
}
